<template>
  <v-container class="">
    <v-row>
      <v-col>
        <v-card class="grey" elevation="" max-width="">
          <v-card-text>
            <img height="120px" width="120px" :src="newVisita.file" alt="" />
            <!-- <img height="120px" width="120px" :src="newVisita.firma" alt="" /> -->
            <v-file-input
              accept="image/*"
              label="imagen entrega"
              @change="fnImgToBase64"
            ></v-file-input>
            <!-- <v-file-input
              accept="image/*"
              label="imagen firma"
              @change="fnFirmaToBase64"
            ></v-file-input> -->
            <v-text-field
              v-model="newVisita.lat"
              label="latitud"
            ></v-text-field>
            <v-text-field
              v-model="newVisita.lng"
              label="longitud"
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-textarea
              outlined
              color=""
              placeholder=""
              error-count=""
              label="Observaciones"
              v-model="newVisita.observaciones"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="fnCrearVisita" dark color="green">
              Crear inicio de labores
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <GmapMap
          ref="mymap"
          :center="center"
          :zoom="12"
          style="width: 100%; height: 300px"
        >
          <GmapMarker
            :position="{ lat: newVisita.lat, lng: newVisita.lng }"
            :draggable="true"
            @drag="updateCoordinatesCarga"
            @click="updateCenter"
          />
        </GmapMap>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment';
export default {
  data() {
    return {
      localConsejeras: [],
      currentVisita: {
        id: null,
        codigo: '',
        nombre_completo: '',
        direccion: '',
        telefono: '',
        saldo: 0,
        premios: [],
        estado: 1,
        createdAt: '',
        updatedAt: '',
        fk_usuario: null,
      },
      center: { lat: 14.599251, lng: -90.55084 },
      newVisita: {
        lat: 14.599251,
        lng: -90.55084,
        file: null,
        fecha_inicio: moment().subtract(6, 'hours'),
        observaciones: null,
      },
    };
  },
  mounted() {
    this.findConsejeras({
      query: {
        $limit: 200,
        fk_id_supervisora: this.$store.state.auth.payload.usuario.codigo,
      },
    }).then((result) => {
      this.localConsejeras = result.data;
    });
  },
  methods: {
    ...mapActions('SupervInicioDeLabores', {
      getVisitas: 'get',
      findVisitas: 'find',
      patchVisita: 'patch',
      crearVisita: 'create',
    }),
    ...mapActions('Consejeras', {
      getConsejeras: 'get',
      findConsejeras: 'find',
      patchConsejera: 'patch',
      crearConsejera: 'create',
    }),
    fnOldTel() {
      this.newVisita.old_tel = this.currentVisita.telefono;
    },
    fnUpdateConsejera() {
      this.newVisita.id_consejera = this.currentVisita.id;
    },
    fnUpdateMotivo(e) {
      this.newVisita.motivo_de_visita_id = e.id;
      this.newVisita.motivo_de_visita = e.label;
    },
    updateCoordinatesCarga(location) {
      this.newVisita.lat = location.latLng.lat();
      this.newVisita.lng = location.latLng.lng();
    },
    updateCenter(location) {
      this.center.lat = location.latLng.lat();
      this.center.lng = location.latLng.lng();
    },
    fnGetBase64Image(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    fnImgToBase64(e) {
      this.fnGetBase64Image(e)
        .then((data) => (this.newVisita.file = data))
        .catch(() => {
          this.newVisita.file = null;
        });
    },
    // fnFirmaToBase64(e) {
    //   this.fnGetBase64Image(e)
    //     .then((data) => (this.newVisita.firma = data))
    //     .catch(() => {
    //       this.newVisita.firma = null;
    //     });
    // },
    fnCrearVisita() {
      this.crearVisita(this.newVisita).then(() => {
        /**
         *
         */
        this.newVisita = {
          lat: 14.599251,
          lng: -90.55084,
          file: null,
          fecha_inicio: moment().subtract(6, 'hours'),
          observaciones: null,
        };
        /**
         *
         */
        this.currentVisita = {
          id: null,
          codigo: '',
          nombre_completo: '',
          direccion: '',
          telefono: '',
          saldo: 0,
          premios: [],
          estado: 1,
          createdAt: '',
          updatedAt: '',
          fk_usuario: null,
        };
      });
    },
  },
  computed: {
    motivos() {
      return this.$store.state.visitas;
    },
    currentUser() {
      return this.$store;
    },
  },
};
</script>
